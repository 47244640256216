import axios from 'axios'
import { store } from '../../redux'
import * as actions from '../../redux/features/storefront.redux'

const { REACT_APP_API_GATEWAY } = process.env

export const additionalProducts = (
  transactionId: string,
  additionalProducts: string[]
) => {
  return axios
    .post(`${REACT_APP_API_GATEWAY}/additional-products/${transactionId}`, {
      additionalProducts,
    })
    .then((response) => {
      store.dispatch(actions.done(response.data))
      return response.data
    })
}
