/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import debounce from 'lodash.debounce'
import {
  ErrorMessage,
  Input,
  ListUi,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import './AddressAutocomplete.css'
import { autocomplete } from '../../services/api/addressValidation/autocomplete'
import { FormField } from '../FormField'
import { getListItemChildren } from './common'

interface Props {
  prefixCode?: string
  label?: string
  infoIconText?: React.ReactNode
}

const DEBOUNCE_MILLIS = 300
const MIN_QUERY_LENGTH = 2

export const ZipCodeAutocomplete = ({ prefixCode, label }: Props) => {
  const { t } = useTranslation()
  const [fieldProps, , { setValue: setAutocompleteQuery }] = useField<string>(
    `customerData.address.zipCode`
  )
  const [{ value: city }, , { setValue: setCity }] = useField<string>(
    `customerData.address.city`
  )
  const [{ value: countryCode }] = useField<string>(
    `customerData.address.countryCode`
  )
  const [{ value: zipCodeSelected }, , { setValue: setZipCodeSelected }] =
    useField<boolean>(`zipCodeSelected`)
  const [, , { setValue: setIsManual }] =
    useField<boolean>(`address[0].isManual`)
  const abortController = useRef<AbortController>()
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [items, setItems] = useState<any[]>([])
  const [currentItem, setCurrentItem] = useState<any>()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [searched, setSearched] = useState<boolean>(false)

  useEffect(() => {
    if (
      currentItem &&
      (fieldProps?.value !== currentItem.zipCode ||
        city !== currentItem.city ||
        countryCode !== currentItem.countryCode)
    ) {
      setError(undefined)
      setItems([])
      setCurrentItem(undefined)
      setZipCodeSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps?.value, city, countryCode])

  useEffect(() => {
    if (initialized) {
      if (currentItem && fieldProps?.value !== currentItem.zipCode) {
        setCurrentItem(undefined)
      }
      onDebouncedAutocompleteQueryChange((fieldProps?.value ?? '').trim())
    } else {
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps?.value])

  const onAutocompleteQueryChange = (newQuery: string) => {
    setSearched(false)
    if (newQuery === query && !error) {
      return
    }
    // eslint-disable-next-line no-unused-expressions
    abortController.current?.abort()
    setQuery(newQuery)
    setError(undefined)
    setItems([])
    if (newQuery.length < MIN_QUERY_LENGTH) {
      abortController.current = undefined
      return
    }
    abortController.current = new AbortController()
    setLoading(true)
    autocomplete(
      {
        zipCode: fieldProps?.value,
        countryCode: prefixCode,
      },
      abortController.current
    )
      .then((i) => {
        setError(undefined)
        setItems(i)
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(t('personal-details:errors:genericError'))
          setItems([])
        }
      })
      .finally(() => {
        abortController.current = undefined
        setLoading(false)
        setSearched(true)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedAutocompleteQueryChange = debounce(
    onAutocompleteQueryChange,
    DEBOUNCE_MILLIS
  )

  return (
    <div className="address-autocomplete">
      <FormField
        testId="zipCode"
        type="input"
        labelText={label}
        {...fieldProps}
        render={(props) => {
          return <Input {...props} autocomplete="nope" />
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {items && items.length > 0 && (
        <ul className="c-list-ui">
          {items.map((item, index) => (
            <li
              className="c-list-ui__item"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => {
                setZipCodeSelected(true)
                setQuery(item?.zipCode)
                setAutocompleteQuery(item.zipCode, false)
                setCity(item.city, false)
                setCurrentItem(item)
                setError(undefined)
                setItems([])
              }}
            >
              {getListItemChildren(item)}
            </li>
          ))}
        </ul>
      )}
      {!items.length && !zipCodeSelected && searched && (
        <ListUi>
          <ListUi.Item
            key="zipCodeError"
            onClick={() => {
              setIsManual(true)
              setItems([])
              setError(undefined)
            }}
          >
            {t('personal-details:manualModeOption')}
          </ListUi.Item>
        </ListUi>
      )}
      {!items.length && !zipCodeSelected && loading && (
        <ListUi>
          <ListUi.Item key="zipCodeLoading">
            <Spinner small />
          </ListUi.Item>
        </ListUi>
      )}
    </div>
  )
}
