import {
  ContentSection,
  DescriptionList,
  Hr,
  Layout,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Output } from '../../../Text'
import summaryContract from '../../../resources/base/content/summaryContract.json'
import { formatPrice } from '../../../services/common/format'
import { shouldShowAdditionalProductsItem } from '../../../services/common/utils'
const SummaryContractSectionVTI = (props) => {
  const { t } = useTranslation()
  const { vehicleData, financialProduct } = props
  // First section
  const deliveryType = vehicleData.delivery.deliveryType
  const descriptionListTitles = Output.resolve(
    `descriptionList.${deliveryType ? `${deliveryType}` : ''}`,
    summaryContract
  )
  const textTypeOfPurchase = summaryContract.descriptionList?.textTypeOfPurchase
  const showItem = shouldShowAdditionalProductsItem(financialProduct)
  const dataContractDetais = [
    textTypeOfPurchase,
    formatPrice(vehicleData?.purchasePriceAmount),
    showItem
      ? formatPrice(showItem?.additionalProductCostAmount?.grossAmount)
      : null,
    formatPrice(
      vehicleData?.delivery?.deliveryCostComponents?.deliveryCostAmount
        ?.grossAmount || 0
    ),
  ]

  // Second summmary section
  const textTotalPrice = Output.resolve(`descriptionList`, summaryContract)
  const dataContractDetaisTotalPrice = [
    formatPrice(financialProduct?.totalAmount?.grossAmount),
  ]

  return (
    <>
      <ContentSection.ComponentWrapper className="u-mb-none">
        <h4 className="u-text-left">{t('summaryContract:title')}</h4>
        <Paragraph className="u-text-left u-mb-large">
          {t('summaryContract:subtitle')}
        </Paragraph>
        <Layout>
          <Layout.Item>
            <Layout>
              {descriptionListTitles?.items?.length > 0 && (
                <Layout.Item default="2/3">
                  <DescriptionList split>
                    {descriptionListTitles.items.map(
                      (item: string, index: number) => {
                        const detail = dataContractDetais?.[index]
                        return (
                          detail && (
                            <DescriptionList.Group  key={item} termText={item}>
                              <DescriptionList.Detail >
                                <span className="u-mr-xlarge">{detail}</span>
                              </DescriptionList.Detail>
                            </DescriptionList.Group>
                          )
                        )
                      }
                    )}
                  </DescriptionList>
                </Layout.Item>
              )}
              <Hr className="u-m-none" />
              {textTotalPrice?.additionalItem?.length > 0 && (
                <Layout.Item default="2/3">
                  <DescriptionList split>
                    {textTotalPrice &&
                      textTotalPrice?.additionalItem?.map(
                        (item: string, index: number) => (
                          <DescriptionList.Group key={item} termText={item}>
                            <DescriptionList.Detail>
                            <span className="u-mr-xlarge">{dataContractDetaisTotalPrice?.[index]}</span>
                            </DescriptionList.Detail>
                          </DescriptionList.Group>
                        )
                      )}
                  </DescriptionList>
                </Layout.Item>
              )}
            </Layout>
          </Layout.Item>
        </Layout>
      </ContentSection.ComponentWrapper>
    </>
  )
}
export default SummaryContractSectionVTI
