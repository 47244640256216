import validation from './validation.json'
import contact from './contact.json'
import app from './app.json'
import confirmation from './confirmation.json'
import wizard from './wizard.json'
import error from './error.json'
import oauth from './oauth.json'
import loading from './loading.json'
import form from './form.json'
import personalDetails from './personal-details.json'
import deliveryDetails from './delivery-details.json'
import navigationBlockedModal from './navigation-blocked-modal.json'
import landingVw from './landing-vw.json'
import discount from './discount.json'
import vehicleNotAvailable from './vehicleNotAvailable.json'
import stepper from './stepper.json'
import footer from './footer.json'
import dataProtectionInfo from './data-protection-info.json'
import marketingCards from './marketing-cards.json'
import emailValidationModal from './email-validation-modal.json'
import expiredSession from './expiredSession.json'
import additionalProducts from './additional-products.json'
import summary from './summary.json'
import summaryVehicle from './summaryVehicle.json'
import transactions from './transactions.json'
import summaryContract from './summaryContract.json'

export const i18n = {
  validation,
  contact,
  app,
  confirmation,
  wizard,
  error,
  oauth,
  landingVw,
  loading,
  form,
  'personal-details': personalDetails,
  'delivery-details': deliveryDetails,
  'marketing-cards': marketingCards,
  navigationBlockedModal,
  discount,
  vehicleNotAvailable,
  stepper,
  footer,
  dataProtectionInfo,
  emailValidationModal,
  expiredSession,
  additionalProducts,
  summary,
  summaryVehicle,
  summaryContract,
  transactions,
}
