import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Form, Spinner } from '@vwfs-bronson/bronson-react'
import getInitialValues from './initial-values'
import { RenderFieldset } from './fieldset'
import {
  ValidationSchemaPrivate,
  ValidationSchemaBusiness,
} from './validation-schema'
import { getDataModels } from '../../../services/redux/features/data-models.redux'
import {
  getFormData,
  saveFormData,
} from '../../../services/redux/features/form.redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { checkTls } from '../../../services/api/checkTls'
import { setTlsValid as setTlsValidAction } from '../../../services/redux/features/custom.redux'
import { trackPage } from '../tracking'

export type PersonalDetailsProps = {
  saveFormData: any
  dataModels: any
  formData: any
}

const PersonalDetails = (props, ref) => {
  const {
    dataModels,
    hidden,
    editPage,
    isBusiness,
    isVtiGis,
    setTlsValid,
    setIsDirty: setIsDirtyParent,
    getValues,
  } = props
  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getCustomerData: () => {
      // eslint-disable-next-line no-nested-ternary
      return isBusiness
        ? { ...formState.current.values.customerData, dateOfBirth: undefined }
        : isVtiGis
          ? formState.current.values.customerData
          : {
              ...formState.current.values.customerData,
              address: {
                ...formState.current.values.customerData.address,
                countryCode: undefined,
              },
            }
    },
    getCompanyData: () => {
      return formState.current.values.companyData
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      return formState.current.isValid
    },
  }))
  const formState = React.useRef({} as any)
  const [initialValid, setInitialValid] = React.useState<boolean | null>(null)
  const [isDirty, setIsDirty] = React.useState(false)
  const [lastEmailSetted, setLastEmailSetted] = React.useState('')

  const regExEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,61}))$/

  const getEmailFormValue = () =>
    formState?.current?.values?.customerData?.contactData?.email

  const onBlur = async () => {
    const email = getEmailFormValue()
    if (email && regExEmail.test(email) && email !== lastEmailSetted) {
      try {
        const result = await checkTls(email)
        setTlsValid(result)
        setLastEmailSetted(email)
      } catch {}
    }
  }

  React.useEffect(() => {
    if (!hidden) {
      trackPage({ firstOpen: !editPage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden, editPage])

  React.useEffect(() => {
    setIsDirtyParent(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const { t } = useTranslation()

  const isInitialValid = (formikProps) => {
    if (initialValid !== null) {
      return initialValid
    }
    // eslint-disable-next-line no-nested-ternary
    const actualValidationSchema = isBusiness
      ? ValidationSchemaBusiness
      : ValidationSchemaPrivate(isVtiGis)

    if (actualValidationSchema) {
      const isValid = actualValidationSchema.isValidSync(
        formikProps.initialValues
      )
      setInitialValid(isValid)
      return isValid
    }
    return false
  }

  return (
    <React.Fragment>
      {Object.keys(dataModels).length ? (
        <Formik
          enableReinitialize
          isInitialValid={isInitialValid}
          initialValues={getInitialValues()}
          validationSchema={
            isBusiness ? ValidationSchemaBusiness : ValidationSchemaPrivate
          }
          onSubmit={() => {}}
          render={(formProps) => {
            getValues(formProps.values)
            const { values, handleSubmit, handleBlur } = formProps
            formState.current = {
              values,
              isValid: formProps.isValid,
              dirty: formProps.dirty,
            }
            setIsDirty(formProps.dirty)
            submitFunction = formProps.submitForm
            const handleSubmitOwn = (e) => {
              handleSubmit(e)
            }

            const handleBlurOwn = (e) => {
              onBlur()
              handleBlur(e)
            }
            const handleChangeOwn = (ev) => {
              if (
                ev.target.name === 'customerData.contactData.mobilePhoneNumber'
              ) {
                formProps.setFieldValue(
                  'customerData.contactData.mobilePhoneNumber',
                  ev.target.value.replace(/\s+/g, '')
                )
              }
              if (ev.target.name === 'customerData.contactData.email') {
                formProps.setFieldValue(
                  'customerData.contactData.email',
                  ev.target.value.replace(/\s+/g, '')
                )
              }
            }

            return (
              <Form
                floatingLabel
                onSubmit={handleSubmitOwn}
                onBlur={handleBlurOwn}
                onChange={handleChangeOwn}
              >
                {RenderFieldset({ t, values, isBusiness, isVtiGis, formProps })}
              </Form>
            )
          }}
        />
      ) : (
        <div className="u-p-xlarge">
          <Spinner section />
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    dataModels: getDataModels(state),
    formData: getFormData(state),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(
  mapStateToProps,
  { saveFormData, setTlsValid: setTlsValidAction },
  null,
  {
    forwardRef: true,
  }
)(React.forwardRef(PersonalDetails))
