import { SessionDataHandling } from '../api/common/SessionDataHandling'
import moment from 'moment'

export const isVW = () => {
  const brand = process.env.REACT_APP_WEBSITE_BRAND
  return brand === 'vw' || brand === 'vw6'
}

export const isVWFS = () => {
  const brand = process.env.REACT_APP_WEBSITE_BRAND
  return brand === 'vwfs'
}

export const isCupra = () => {
  const brand = process.env.REACT_APP_WEBSITE_BRAND
  return brand === 'cupra'
}

export const isMandatoryLogin = () => {
  return (
    process.env.REACT_APP_MANDATORY_LOGIN &&
    process.env.REACT_APP_MANDATORY_LOGIN === 'true'
  )
}

export const isVaFa = (storefrontData) => {
  return storefrontData?.salesChannel?.originSystem === 'VAFA'
}
export const isVtiGiS = (storefrontData) => {
  return storefrontData?.salesChannel?.originSystem === 'VTI_GIS'
}
export const isDealershop = (storefrontData) => {
  return storefrontData?.salesChannel?.originSystem === 'DEALERSHOP'
}
export const isBusiness = (storefrontData) => {
  return storefrontData?.customerData?.partnerType === 'BUSINESS'
}
export const isPrivate = (storefrontData) => {
  return storefrontData?.customerData?.partnerType === 'PRIVATE'
}
export const isVehicleDeliveryTypeHomeDelivery = (storefrontData) => {
  return storefrontData?.vehicleData?.delivery?.deliveryType === 'HOME_DELIVERY'
}
export const isVehicleDeliveryTypeDealerShop = (storefrontData) => {
  return storefrontData?.vehicleData?.delivery?.deliveryType === 'DEALERSHOP'
}
export const isDealerRolePickUp = (storefrontData) => {
  return storefrontData?.dealerData?.additionalDealers?.some(
    (dealer) => dealer.dealerRole === 'PICKUP'
  )
}
export const getOriginSystem = (storefrontData) =>
  storefrontData?.salesChannel?.originSystem

export const notFalsy = (value) => {
  return value !== undefined && value !== null
}

export const saveSesionData = (data) => {
  const sessionData = {
    ...(data?.transactionId && { transactionId: data.transactionId }),
    ...(data?.token && { token: data.token }),
  }
  SessionDataHandling.saveSessionDataToStorage(sessionData)
}

export const shouldShowAdditionalProductsItem = (financialProduct) => {
 return financialProduct?.additionalProducts?.find(
    (product) =>
      product.isSelected === true &&
      product.qualifiers?.find((item) => item.id === 'REG1')
  )
}

export const monthsDifference = (startDate, endDate) =>
  Math.abs(moment(endDate).diff(moment(startDate), 'months'))
export const isENVKVNew = (vehicleData) =>
  ((!vehicleData?.isUsed && vehicleData?.odometer < 1000) ||
    monthsDifference(vehicleData?.initialRegistrationDate, moment.now()) < 8) &&
  !vehicleData?.model?.economics?.at(0)?.skipEnvkv

export const openTabUrl = (url) => {
  window.open(url, '_blank')
}
