/* eslint-disable no-nested-ternary */
import React from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  ContentSection,
  Fieldset,
} from '@vwfs-bronson/bronson-react'
import * as Yup from 'yup'
import get from 'lodash/get'
import getInitialValues from './initial-values'
import DataProtectionFieldset from './Fieldset'
import { scrollToElement } from '../../../services/common/form'
import { getFormData } from '../../../services/redux/features/form.redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { getTlsValid } from '../../../services/redux/features/custom.redux'
import { setDataPrivacy } from '../../../services/analytics/functions'
import SummaryVehicleSection from '../SummaryVehicleSection'
import SummarySection from '../SummarySection'
import SummaryContractSectionVTI from '../SummaryContractSectionVTI'
import { isDealershop } from '../../../services/common/utils'

const DataProtection = (props, ref) => {
  const {
    tlsValid,
    storefrontData,
    hasDefectsSelected,
    validateAllForms,
    finishJourney,
    isBusiness,
    isVtiGis,
    setIsDirty: setIsDirtyParent,
    hasSubmited,
    getValues,
  } = props
  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getData: () => {
      return formState.current.formProps.values
    },
    validate: () => {
      return formState.current.formProps.isValid
    },
  }))

  const { formData } = props
  const formState = React.useRef({})
  const [isSubmiting, setSubmiting] = React.useState(false)
  const [isDirty, setIsDirty] = React.useState(false)
  const { t } = useTranslation()

  const handleSubmit = async (e, formProps) => {
    setSubmiting(true)
    hasSubmited(true)
    Object.keys(formProps.values).forEach((field) => {
      formProps.setFieldTouched(field)
    })
    e.preventDefault()
    const isPersonalDataFilled = await validateAllForms()
    const {
      allowPersonalizedMarketing,
      allowMailCommunication,
      allowPhoneAds,
      allowMailAds,
      acceptVehicleDefects,
      acceptLimitationPeriodVafa,
      acceptPrecontractualInformationVafa,
      acceptVehicleOwnership,
      acceptLeasingTermination,
      acceptLeasingServices,
      acceptVehicleDifferences,
      acceptLimitationPeriodVti,
      acceptPrecontractualInformationVti,
      acceptPurchaseConditionsVti,
      acceptInsuranceDocuments,
      acceptPurchaseConditionsVafa,
      acceptVehicleCondition,
      acceptLimitationPeriodDealershop,
      acceptPrecontractualInformationDealershop,
      acceptSalesTerms,
    } = formProps.values
    if (isPersonalDataFilled && formProps.isValid) {
      setDataPrivacy(formProps.values)
      finishJourney({
        allowPhoneAds,
        allowMailAds,
        allowPersonalizedMarketing,
        allowMailCommunication,
        acceptVehicleDefects,
        acceptLimitationPeriodVafa,
        acceptPrecontractualInformationVafa,
        acceptVehicleOwnership,
        acceptLeasingTermination,
        acceptLeasingServices,
        acceptVehicleDifferences,
        acceptLimitationPeriodVti,
        acceptPrecontractualInformationVti,
        acceptPurchaseConditionsVti,
        acceptInsuranceDocuments,
        acceptPurchaseConditionsVafa,
        acceptVehicleCondition,
        acceptLimitationPeriodDealershop,
        acceptPrecontractualInformationDealershop,
        acceptSalesTerms,
      })
    }
  }

  const onSubmit = (values, actions) => {
    // updateValidSections('dataProtection', true)
    actions.setSubmitting(false)
  }

  const onChange = async (e, formProps) => {
    if (e?.target?.name === 'allowAllAds') {
      formProps.setValues({
        ...formProps.values,
        allowAllAds: e.target.checked,
        allowPhoneAds: e.target.checked,
        allowMailAds: e.target.checked,
        allowPersonalizedMarketing: e.target.checked,
      })
    } else {
      const { target } = e
      const { name } = target
      if (name) {
        const value = target.type === 'checkbox' ? target.checked : target.value
        await formProps.setFieldValue(name, value)
        await formProps.setFieldTouched(name)
      }
    }
  }

  const ValidationSchema = Yup.object().shape({
    allowMailCommunication: Yup.boolean().test(
      'required',
      t('validation:required'),
      (value) => {
        return tlsValid || (!tlsValid && value === true)
      }
    ),
  })
  const ValidationSchemaVafaPrivate = ValidationSchema.shape({
    acceptPrecontractualInformationVafa: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptVehicleOwnership: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptLeasingTermination: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptLeasingServices: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
  })

  const ValidationSchemaVafaBusiness = ValidationSchema.shape({
    acceptPurchaseConditionsVafa: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptVehicleCondition: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptVehicleOwnership: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptLeasingTermination: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptLeasingServices: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
  })

  const ValidationSchemaVtiGis = ValidationSchema.shape({
    acceptVehicleDifferences: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptLimitationPeriodVti: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptPrecontractualInformationVti: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptPurchaseConditionsVti: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptInsuranceDocuments: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
  })

  const ValidationSchemaDealershop = ValidationSchema.shape({
    acceptLimitationPeriodDealershop: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptPrecontractualInformationDealershop: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
    acceptSalesTerms: Yup.boolean()
      .required(t('validation:required'))
      .oneOf([true], t('validation:required')),
  })

  React.useEffect(() => {
    setIsDirtyParent(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  React.useEffect(() => {
    if (isSubmiting) {
      setTimeout(() => {
        if (document.querySelector('.is-error')) {
          scrollToElement('.is-error', 'start')
        }
        setSubmiting(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmiting])

  React.useEffect(() => {
    formState.current.formProps.validateForm()
  }, [tlsValid])

  React.useEffect(() => {
    formState.current.formProps.validateForm()
  }, [hasDefectsSelected])

  return (
    <>
      <Formik
        initialValues={getInitialValues(formData, storefrontData)}
        validationSchema={
          isVtiGis
            ? ValidationSchemaVtiGis
            : isDealershop(storefrontData)
              ? ValidationSchemaDealershop
              : isBusiness
                ? ValidationSchemaVafaBusiness
                : ValidationSchemaVafaPrivate
        }
        onSubmit={onSubmit}
        render={(formProps) => {
          setIsDirty(formProps.dirty)
          formState.current = { formProps }
          return (
            <Form
              id="data-protection-form"
              onChange={(e) => onChange(e, formProps)}
              onSubmit={(e) => handleSubmit(e, formProps)}
            >
              <DataProtectionFieldset
                inspectionReportLink={
                  get(storefrontData, 'vehicleData.appraisals[0].url') || '#'
                }
                isBusiness={isBusiness}
                isVtiGis={isVtiGis}
                t={t}
                hasDefectsSelected={hasDefectsSelected}
                formProps={formProps}
              />
              <ContentSection className="u-pt-small u-pb-small">
                <Card>
                  <SummarySection
                    isBusiness={isBusiness}
                    getValues={getValues}
                  />
                </Card>
              </ContentSection>
              <ContentSection className="u-pt-small u-pb-small">
                <Card className="u-text-left">
                  <SummaryVehicleSection
                    purchaseDate={
                      storefrontData.financialProduct.confirmedPurchaseDate
                    }
                    vehicleData={storefrontData.vehicleData}
                    isVtiGis={isVtiGis}
                    isBusiness={isBusiness}
                    selectedCondition={formData?.condition}
                    defectsDescription={formData?.defectsDescription}
                  />
                  {!isVtiGis && <Fieldset.Row className="u-text-right u-mt-large">
                    <Button
                      className="u-text-center"
                      testId="nextButton"
                      type="submit"
                    >
                      {t('summaryVehicle:nextButton')}
                    </Button>
                  </Fieldset.Row>}
                </Card>
              </ContentSection>
              {isVtiGis && <ContentSection className="u-pt-small u-pb-small">
                <Card className="u-text-left">
                <SummaryContractSectionVTI
                vehicleData={storefrontData.vehicleData}
                financialProduct={storefrontData.financialProduct}
                  /><Fieldset.Row className="u-text-right u-mt-large">
                    <Button
                      className="u-text-center"
                      testId="nextButton"
                      type="submit"
                    >
                      {t('summaryVehicle:nextButton')}
                    </Button>
                  </Fieldset.Row>
                </Card>
              </ContentSection>}
            </Form>
          )
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: getFormData(state),
    tlsValid: getTlsValid(state),
    showVWDSEData: true,
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  React.forwardRef(DataProtection)
)
